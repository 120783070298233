.intro-text {
  text-align: left;
}
.intro-text h2 {
  position: relative;
  z-index: 2;
  font-size: 3rem;
  color: #4f5d73;
  font-weight: 700;
  opacity: 1;
  visibility: visible;

  margin-bottom: 40px;
}

.intro-text .div-dec {
  width: 80px;
  height: 6px;
  border-radius: 3px;
  background-color: #4f4d4d;
  margin-bottom: 40px;
  opacity: 1;
  visibility: visible;
}

.intro-text p {
  color: #fff;
  margin-right: 60px;
  margin-bottom: 50px;
  opacity: 1;
  visibility: visible;
}

.intro-text h2 em {
  font-style: normal;
  color: #ffa71a;
}

.intro-text .buttons {
  display: flex;
  flex-wrap: wrap;
}
.intro-text .buttons div {
  padding: 0 10px;
}

.intro-text .buttons .green-button {
  display: inline;
  float: left;
  margin-right: 20px;
}

.displaytext {
  font-size: 2rem;
  font-weight: bold;
}
.introsection {
  height: calc(100vh - 110px);
}
.nameListque {
  width: 100%;
}
.nameListque li {
  background-color: transparent;
  color: #4f4d4d;
  text-transform: uppercase;
}
.nameListque li .avatar {
  font-weight: bolder;
  font-size: 2.5rem;
  color: white;
}
.nameListque .placeholder-que li {
  opacity: 0.5;
}

.intro-r-text h2 {
  font-weight: 300;
  font-style: italic;
  color: rgb(149, 146, 146);
  margin: 2rem 0;
}

.nameListque {
  max-height: 450px;
  overflow-y: auto;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
}

.card-container .subcategory-card {
  padding: 1rem;
  border: 1px solid #cfcfcf;
  background: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 400;
  position: relative;
}

.card-container .subcategory-card:hover {
  background: #e9e9e9;
}

.card-container .subcategory-card::after {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  top: 5px;
  right: 5px;
  background: none;
}

.card-container .subcategory-card p {
  margin: 0;
}

.card-container .subcategory-card.active {
  background: #e0e0e0;
  border-color: #8d8d8d;
}

.card-container .subcategory-card.active::after {
  background: url("../assets/images/checked.png");
  background-size: cover;
  filter: contrast(30%);
}
