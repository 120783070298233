.profilecardcontainer {
  height: calc(100vh - 110px);
}
.service-input {
  height: 7rem !important;
  background-color: #f5f5f5 !important;
  position: relative;
}

.service-input::after {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  top: 5px;
  right: 5px;
  background: none;
}

.service-input.selected::after {
  background: url("../assets/images/checked.png");
  background-size: cover;
  filter: contrast(30%);
}

.service-input:hover {
  background-color: #e7e7e7 !important;
}
.service-input .card-body {
  display: flex;
  justify-content: center;
  align-items: center;
}
.service-input .card-img {
  padding: 15px;
  border: 1px solid #c1c1c1 !important;
}
.card.selected {
  border-color: #535353 !important ;
}

.service-container h6 {
  font-size: 1.2rem;
  width: fit-content;
  border-bottom: 1px solid #ececec;
}
