tfoot button {
  padding: 0.3rem 0.7rem;
  font-size: 0.9rem;
  border-radius: 3px;
  border: 1px solid #ccc;
  background: none;
}

table select {
  padding: 0.3rem 0.5rem;
  outline: none;
  border: 1px solid #ececec;
  width: 100%;
}
